<template>
  <div class="pa-5">
    <ContentNotFound :message="errorMsg" :loading="loading" v-if="!isAvailable">
      <template v-slot:action>
        <v-card-actions>
          <v-btn @click="$router.back()" color="secondary" class="px-10">
            <v-icon left>keyboard_arrow_left</v-icon>
            Kembali
          </v-btn>
          <v-btn @click="getDetailEmployee" color="primary" class="px-10">
            <v-icon left>sync</v-icon>
            Muat Ulang
          </v-btn>
        </v-card-actions>
      </template>
    </ContentNotFound>
    <template v-else>
      <v-card flat>
        <v-btn
          v-if="isEditPegawai"
          @click="handleUpdate"
          :loading="checkLoading"
          fab
          small
          dark
          :color="isUpdate ? 'error' : 'primary'"
          class="float-right mr-6 mt-4"
        >
          <v-icon>{{ isUpdate ? "close" : "edit" }}</v-icon>
        </v-btn>
        <v-row class="mt-4 ml-4" id="input-custom">
          <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12">
            <p class="ma-1 subtitle-2 font-weight-regular headline-color">
              Nama Pegawai
            </p>
            <p class="ma-1 subtitle-2 font-weight-bold headline-color">
              <v-text-field
                v-if="isUpdate"
                outlined
                dense
                hide-details
                v-model="personal.nama"
                :disabled="!$_.isEmpty(personal.NIP)"
              ></v-text-field>
              <span v-else>{{ personal.nama }}</span>
            </p>
          </v-col>
          <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12">
            <p class="ma-1 subtitle-2 font-weight-regular headline-color">
              NIP
            </p>
            <p class="ma-1 subtitle-2 font-weight-bold headline-color">
              <v-text-field
                v-if="isUpdate"
                outlined
                dense
                hide-details
                :disabled="!$_.isEmpty(personal.NIP)"
                v-model="personal.NIP"
              ></v-text-field>
              <span v-else>{{ personal.NIP }}</span>
            </p>
          </v-col>
          <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12">
            <p class="ma-1 subtitle-2 font-weight-regular headline-color">
              Nomor ID Presensi
            </p>
            <p class="ma-1 subtitle-2 font-weight-bold headline-color">
              <v-text-field
                v-if="isUpdate"
                outlined
                dense
                hide-details
                v-model="personal.presensi_id"
                :disabled="!$_.isEmpty(personal.NIP)"
              ></v-text-field>
              <span v-else>{{ personal.presensi_id }}</span>
            </p>
          </v-col>
        </v-row>
        <v-card-actions class="pl-7">
          <v-btn
            small
            outlined
            color="#717171"
            class="px-5 text-capitalize"
            @click="visible = true"
            >Pengajuan</v-btn
          >
          <v-btn
            small
            outlined
            color="#717171"
            class="ml-4 px-5 text-capitalize"
            @click="getToPage('schedule')"
            >Penetapan</v-btn
          >
        </v-card-actions>
        <v-row>
          <v-col cols="12">
            <v-tabs show-arrows height="50" v-model="tab" id="tab-data">
              <v-tab
                v-for="(item, i) in tabs"
                v-bind:key="i"
                class="mr-2 px-5 text-upppercase"
                >{{ item.text }}</v-tab
              >
            </v-tabs>
            <v-tabs-items>
              <transition name="slide-fade" mode="out-in">
                <keep-alive>
                  <component
                    :is="tabs[tab].component"
                    :isUpdate="isUpdate"
                    @toggle="toggleUpdate"
                    @on-refresh="getDetailEmployee"
                  />
                </keep-alive>
              </transition>
              <v-card-actions v-if="isUpdate && tab < 4">
                <v-spacer />
                <v-btn
                  small
                  :loading="formLoading"
                  color="#FBB005"
                  class="px-12 subtitle-2 text-capitalize"
                  @click="handleSubmit"
                  >Simpan</v-btn
                >
              </v-card-actions>
            </v-tabs-items>
          </v-col>
        </v-row>
        <v-dialog v-model="visible" persistent max-width="350">
          <v-card class="pa-5 rounded-lg">
            <v-btn @click="visible = false" x-small icon class="float-right">
              <v-icon>close</v-icon>
            </v-btn>
            <v-card-text class="subtitle-2 px-0 font-weight-bold">
              Pengajuan
            </v-card-text>
            <v-divider class="mb-5" />
            <v-card-text class="subtitle-2 px-0 font-weight-regular">
              Senin, 17-07-2020
            </v-card-text>
            <v-select
              :items="units"
              placeholder="Unit Kerja"
              outlined
              dense
              hide-details
            ></v-select>
            <v-card-actions class="mt-3">
              <v-spacer />
              <v-btn
                small
                color="#FBB005"
                class="px-12 subtitle-2 text-capitalize"
                @click="visible = false"
                >Ajukan</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
    </template>
  </div>
</template>
<script>
import PegawaiService from "@/services/resources/pegawai.service";
import { mapEmployeeRootField } from "@/store/helpers";
import { mapGetters } from "vuex";
import { ACTION_ACCESS } from "@/common/constant";
const ContentNotFound = () => import("@/components/Content/NotFound");

// Tab
const DataPribadi = () => import("./Tab/DataPribadi");
const DataKepegawaian = () => import("./Tab/DataKepegawaian");
const DataKeluarga = () => import("./Tab/DataKeluarga");
const DataRiwayat = () => import("./Tab/DataRiwayat/index");
const FileDigital = () => import("./Tab/FileDigital");

export default {
  components: {
    DataPribadi,
    DataKepegawaian,
    DataKeluarga,
    DataRiwayat,
    FileDigital,
    ContentNotFound
  },
  data() {
    return {
      id: this.$route.params ? this.$route.params.employeeId : null,
      isUpdate: false,
      loading: false,
      formLoading: false,
      checkLoading: false,
      errorMsg: "",
      tab: 0,
      tabs: [
        {
          id: "data-pribadi",
          text: `Data Pribadi`,
          icon: "",
          component: "DataPribadi"
        },
        {
          id: "data-kepegawaian",
          text: `Data Kepegawaian`,
          icon: "",
          component: "DataKepegawaian"
        },
        {
          id: "data-keluarga",
          text: `Data Keluarga`,
          icon: "",
          component: "DataKeluarga"
        },
        {
          id: "data-riwayat",
          text: `Data Riwayat`,
          icon: "",
          component: "DataRiwayat"
        },
        {
          id: "file-digital",
          text: `File Digital`,
          icon: "",
          component: "FileDigital"
        }
      ],
      tabsTemp: [],
      visible: false,
      units: ["Kenaikan Pangkat", "Penurunan Pangkat"]
    };
  },
  computed: {
    ...mapEmployeeRootField({
      personal: "personal",
      employee: "employee",
      keluarga: "keluarga",
      unit_kerja: "unit_kerja",
      riwayat: "riwayat"
    }),
    ...mapGetters({
      currentUser: "auth/currentUser"
    }),
    isEditPegawai() {
      return ACTION_ACCESS.PEGAWAI_PRESENSI.EDIT_PEGAWAI.find(
        d => d == this.currentUser.role
      );
    },
    isAvailable() {
      return this.personal.NIP != null;
    }
  },
  watch: {
    isUpdate(val) {
      if (!val) {
        this.getDetailEmployee();
      }
    }
  },
  methods: {
    addNumber(number) {
      number = String(number);
      if (number.length == 1) {
        return `0${number}`;
      }
      return `${number}`;
    },
    handleSubmit() {
      let personal = this.$_.cloneDeep(this.personal);
      personal.pendidikan.jenjang = personal.pendidikan.jenjang?.kode_pend;
      personal.pendidikan.bidang_studi =
        personal.pendidikan.bidang_studi?.kode_studi;
      personal.rt_rw = personal.rt + "/" + personal.rw;
      personal.kelurahan = personal.kelurahan?.kode_wilayah;
      personal.kecamatan = personal.kecamatan?.kode_wilayah;
      personal.kabupaten = personal.kabupaten?.kode_wilayah;
      personal.provinsi = personal.provinsi?.kode_wilayah;
      personal.kelamin = personal.kelamin == "Pria" ? 1 : 2;
      let employee = this.$_.cloneDeep(this.employee);
      employee.jabatan_eselon.jabatan =
        employee.jabatan_eselon.jabatan?.jabatan_id;
      employee.jabatan_eselon.eselon =
        employee.jabatan_eselon.eselon?.eselon_id;
      employee.golongan_pangkat.gol = employee.golongan_pangkat.gol?.gol;
      employee.golongan_pangkat.pangkat =
        employee.golongan_pangkat.pangkat?.pangkat_id;
      let unit_kerja = this.$_.cloneDeep(this.unit_kerja);
      let payload = {
        action: 2,
        personal,
        employee: {
          ...employee,
          unit_kerja: {
            unit_utama_id: unit_kerja.unit_utama?.unit_utama_id,
            unit_kerja_2_id: unit_kerja.unit_kerja_2?.unit_kerja_2_id,
            unit_kerja_3_id: unit_kerja.unit_kerja_3?.unit_kerja_3_id,
            unit_kerja_4_id: unit_kerja.unit_kerja_4
              ? unit_kerja.unit_kerja_4.unit_kerja_4_id
              : null
          }
        }
      };
      this.$confirm({
        title: "Konfirmasi",
        message: `Apakah Anda yakin akan merubah data ini?`,
        button: {
          no: "Batal",
          yes: "Ya"
        },
        callback: confirm => {
          if (confirm) {
            this.savePegawai(payload);
          }
        }
      });
    },
    handleUpdate() {
      if (this.isUpdate) {
        this.$confirm({
          title: "Confirm",
          message: `Are you sure you want to cancel this request?`,
          button: {
            no: "Batal",
            yes: "Ya"
          },
          callback: confirm => {
            if (confirm) {
              this.toggleUpdate();
            }
          }
        });
      } else {
        this.toggleUpdate();
      }
    },
    toggleUpdate() {
      if (this.isUpdate) {
        this.tab = 0;
        this.tabs = this.tabsTemp;
        this.isUpdate = !this.isUpdate;
      } else {
        this.checkNIP();
      }
    },
    // Service
    async checkNIP() {
      try {
        this.checkLoading = true;
        await PegawaiService.checkNIP({
          NIP: this.personal.NIP
        })
          .then(response => {
            const { status, history_id } = response.data.message;
            if (status) {
              this.tab = 0;
              this.tabs = this.tabs.filter(
                d => d.id == "data-pribadi" || d.id == "data-kepegawaian"
              );
              this.isUpdate = !this.isUpdate;
            } else {
              if (history_id) {
                this.$confirm({
                  title: "Konfirmasi",
                  message: `Request perubahan masih dalam proses, ingin melihat perubahan tersebut?`,
                  button: {
                    no: "Tidak",
                    yes: "Ya"
                  },
                  callback: confirm => {
                    if (confirm) {
                      this.getToPage("employee-updated.detail", {
                        params: { historyId: history_id }
                      });
                    } else {
                      this.tab = 0;
                      this.tabs = this.tabs.filter(
                        d =>
                          d.id == "data-pribadi" || d.id == "data-kepegawaian"
                      );
                      this.isUpdate = !this.isUpdate;
                    }
                  }
                });
              } else {
                this.tab = 0;
                this.tabs = this.tabs.filter(
                  d => d.id == "data-pribadi" || d.id == "data-kepegawaian"
                );
                this.isUpdate = !this.isUpdate;
              }
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.checkLoading = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getDetailEmployee() {
      this.$store.commit("employee/resetEmployeeState");
      this.errorMsg = "";
      try {
        this.loading = true;
        await PegawaiService.getDetail(this.id)
          .then(response => {
            const { status, message, data } = response.data;
            if (status) {
              data.employee.cpns.tgl_mulai_CPNS = data.employee.cpns
                .tgl_mulai_CPNS
                ? this.$moment(data.employee.cpns.tgl_mulai_CPNS).format(
                    "YYYY-MM-DD"
                  )
                : null;
              data.employee.cpns.tgl_sk_CPNS = data.employee.cpns.tgl_sk_CPNS
                ? this.$moment(data.employee.cpns.tgl_sk_CPNS).format(
                    "YYYY-MM-DD"
                  )
                : null;
              data.employee.golongan_pangkat.tmt_gol = data.employee
                .golongan_pangkat.tmt_gol
                ? this.$moment(data.employee.golongan_pangkat.tmt_gol).format(
                    "YYYY-MM-DD"
                  )
                : null;
              data.employee.golongan_pangkat.tgl_sk_gol = data.employee
                .golongan_pangkat.tgl_sk_gol
                ? this.$moment(
                    data.employee.golongan_pangkat.tgl_sk_gol
                  ).format("YYYY-MM-DD")
                : null;
              data.employee.mkg = data.employee.golongan_pangkat.MKG
                ? data.employee.golongan_pangkat.MKG
                : null;
              data.employee.jabatan_eselon.tgl_sk_jabatan = data.employee
                .jabatan_eselon.tgl_sk_jabatan
                ? this.$moment(
                    data.employee.jabatan_eselon.tgl_sk_jabatan
                  ).format("YYYY-MM-DD")
                : null;
              data.employee.mks = data.employee.jabatan_eselon.MKS
                ? data.employee.jabatan_eselon.MKS
                : null;
              data.employee.jabatan_eselon.tmt_jabatan = data.employee
                .jabatan_eselon.tmt_jabatan
                ? this.$moment(data.employee.jabatan_eselon.tmt_jabatan).format(
                    "YYYY-MM-DD"
                  )
                : null;
              data.employee.pns.tgl_mulai_PNS = data.employee.pns.tgl_mulai_PNS
                ? this.$moment(data.employee.pns.tgl_mulai_PNS).format(
                    "YYYY-MM-DD"
                  )
                : null;
              data.employee.pns.tgl_sk_PNS = data.employee.pns.tgl_sk_PNS
                ? this.$moment(data.employee.pns.tgl_sk_PNS).format(
                    "YYYY-MM-DD"
                  )
                : null;
              data.employee.perkiraan_pensiun = data.employee.tgl_pensiun
                ? data.employee.tgl_pensiun
                : null;
              data.personal.photo = data.personal.foto
                ? data.personal.foto
                : null;
              data.personal.tanggal_lahir = data.personal.tanggal_lahir
                ? this.$moment(data.personal.tanggal_lahir).format("YYYY-MM-DD")
                : null;
              data.personal.rt = data.personal.rt ? data.personal.rt : null;
              data.personal.rw = data.personal.rw ? data.personal.rw : null;

              data.personal.kelurahan = {
                kode_wilayah: data.personal.kelurahan,
                name: data.personal.kelurahan_name
              };
              data.personal.kecamatan = {
                kode_wilayah: data.personal.kecamatan,
                name: data.personal.kecamatan_name
              };
              data.personal.kabupaten = {
                kode_wilayah: data.personal.kabupaten,
                name: data.personal.kabupaten_name
              };
              data.personal.provinsi = {
                kode_wilayah: data.personal.provinsi,
                name: data.personal.provinsi_name
              };
              data.personal.pendidikan.jenjang =
                data.personal.pendidikan.jenjang_data;
              data.personal.pendidikan.bidang_studi =
                data.personal.pendidikan.bidang_studi_data;
              data.employee.jabatan_eselon.jabatan =
                data.employee.jabatan_eselon.jabatan_data;
              data.employee.jabatan_eselon.eselon =
                data.employee.jabatan_eselon.eselon_data;
              data.employee.golongan_pangkat.gol =
                data.employee.golongan_pangkat.gol_data;
              data.employee.golongan_pangkat.pangkat =
                data.employee.golongan_pangkat.pangkat_data;

              data.personal.tanggal_lahir = data.personal.tgl_lahir
                ? this.$moment(data.personal.tgl_lahir).format("YYYY-MM-DD")
                : null;

              if (data.keluarga) {
                Object.keys(data.keluarga).forEach(key => {
                  data.keluarga[key].map(item => {
                    item.tanggal_lahir = item.tanggal_lahir
                      ? this.$moment(item.tanggal_lahir).format("YYYY-MM-DD")
                      : null;
                    item.tanggal_nikah = item.tanggal_nikah
                      ? this.$moment(item.tanggal_nikah).format("YYYY-MM-DD")
                      : null;
                    return item;
                  });
                });
              }

              this.personal = this.$_.merge(this.personal, data.personal);
              this.employee = this.$_.merge(this.employee, data.employee);
              this.keluarga = this.$_.merge(this.keluarga, data.keluarga);
              this.unit_kerja = this.$_.merge(this.unit_kerja, data.unit_kerja);
              this.riwayat = this.$_.merge(this.riwayat, data.riwayat);
            } else {
              this.errorMsg = message;
            }
          })
          .catch(error => {
            this.errorMsg = "Data pegawai tidak ditemukan";
            throw new Error(error);
          })
          .finally(() => (this.loading = false));
      } catch (err) {
        console.log(err);
      }
    },
    async savePegawai(data) {
      try {
        this.formLoading = true;
        await PegawaiService.create(data)
          .then(response => {
            const { status, message, error } = response.data;
            if (status) {
              this.toggleUpdate();
              this.getDetailEmployee();
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "success"
              });
            } else {
              if (Object.entries(error).length > 0) {
                this.$store.commit("snackbar/setSnack", {
                  show: true,
                  message: error,
                  color: "error"
                });
              } else {
                this.$store.commit("snackbar/setSnack", {
                  show: true,
                  message: message,
                  color: "error"
                });
              }
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.formLoading = false));
      } catch (err) {
        console.error(err);
      }
    }
  },
  mounted() {
    this.getDetailEmployee();
    this.tabsTemp = this.tabs;
  }
};
</script>
<style lang="scss">
#tab-custom .v-tabs-bar {
  background-color: transparent;
}
#tab-custom .v-tab {
  background-color: #e0e0e0;
  color: #555555;
  border-radius: 15px 15px 0px 0px;
  &.v-tab--active {
    background-color: #1792e6;
    color: #ffffff;
  }
}
#tab-item-custom.v-tabs-items {
  border-top: 3px solid #1792e6;
}
#tab-data .v-tab {
  font-size: 0.675rem;
}
#input-custom .v-input {
  font-size: 0.675rem;
}
</style>
